import React,{useState,useCallback} from "react";
import {
  Grid,
  GridItem,
  HStack,
  VStack,
  Button,
  Box,
  Input,
  Heading,
  Text,
  useBreakpointValue,
  FormControl,
  FormLabel,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { ADD_REWARD } from "../core/req";

export default function AddPacks() {
    const width = useBreakpointValue({base:"90%",lg:"50%"})
    const toast = useToast()
    const [AddRewards, { loading }] = useMutation(ADD_REWARD);
    const [reward, setReward] = useState({
        label:"",
        level: "",
        cash: "",
        gift: "",
        giftLabel:"",
        currency:"XOF"
    })
  const creator =  useCallback(
      async() => {
        const rewardData = await AddRewards({
          variables: {
              data: {
                label: reward.label,
                level: parseInt(reward.level),
                cash: parseInt(reward.cash),
                gift: parseInt(reward.gift),
                giftLabel: reward.giftLabel,
                currency: "XOF"
              }
           },
        });
        if(rewardData.data.createReward.message){
          toast({
            title: "L'ajout a échoué",
            description: `${rewardData.data.createReward.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        else{
          toast({
            title: "Ajout avec succès",
            description: "Vous venez d'ajouter une récompense",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
         setTimeout(() => {
          window.location.reload()
         }, 5000);
        }
      },
      [AddRewards,toast, reward],
    );
  return (
    <VStack w="full" h="full" py={8} bg="primary.50">
      <VStack w={width} h="full" py={8} spacing={8}>
        <FormControl>
          <FormLabel>Nom de la récompense</FormLabel>
          <Input bg="#fff" value={reward.label} onChange={(e:any)=>{setReward({...reward,label:e.target.value})}} />
        </FormControl>
        <FormControl>
          <FormLabel>Valeur en cash</FormLabel>
          <Input bg="#fff" placeholder="ex: 45000" value={reward.cash} onChange={(e:any)=>{setReward({...reward,cash:e.target.value})}} />
        </FormControl>
        <FormControl>
          <FormLabel>Valeur en cadeau</FormLabel>
          <Input bg="#fff" placeholder="ex: 45000" value={reward.gift} onChange={(e:any)=>{setReward({...reward,gift:e.target.value})}} />
        </FormControl>
        <FormControl>
          <FormLabel>Nom du cadeau</FormLabel>
          <Input bg="#fff" placeholder="ticket" value={reward.giftLabel} onChange={(e:any)=>{setReward({...reward,giftLabel:e.target.value})}} />
        </FormControl>
        <FormControl>
          <FormLabel>Niveau de récompense</FormLabel>
          <Select onChange={(e:any)=>{setReward({...reward,level:e.target.value})}} bg="#fff">
            <option>Selectionnez le niveau de la récompense</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </Select>
        </FormControl>
        <FormControl>
          <Button isLoading={loading} onClick={creator} color="primary.50" _hover={{bg:"orangered"}} bg="primary.800">AJOUTER</Button>
        </FormControl>
        <VStack w="full">
            <Text color="primary.400">Le montant associé à une récompense est obligatoirement numérique.
            </Text>
        </VStack>
      </VStack>
    </VStack>
  );
}
