import React from 'react';
import {
    Grid,
    GridItem,
    HStack,
    VStack,
    Button,
    Box,
    Input,
    Heading,
    Text,
    useBreakpointValue,
  } from "@chakra-ui/react";
  import { Link } from 'react-router-dom';

export default function PaidOut() {
  return (
    <VStack w='full' bg="#ddd">
        <HStack w='full' p={6} justifyContent="space-between">
            <Text fontSize={"2xl"} fontWeight="bold">Paiements éffectués</Text>
            <Link to="/home/payment">
          <Button
            size="sm"
            colorScheme={"transparent"}
            _hover={{ bg: "orange" }}
            color="primary.50"
            bg="primary.900"
          >
            PAIEMENTS RECUS
          </Button>
        </Link>
        </HStack>
        <HStack w='90%' borderLeft={"10px solid #f25a2b"} rounded="lg" bg="#fff" boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"} my={8} h='80px'>
        <Text px={8}>aucun paiement éffectué</Text>
      </HStack>
    </VStack>
  );
}
