import { HStack, VStack,FormControl,FormLabel, Switch } from "@chakra-ui/react";
import React from "react";

export default function Settings() {
  return (
    <VStack w="full">
      <HStack w="full" p={4} bg="#fff" alignItems="center" justifyContent={"flex-end"}>
        <FormControl display="flex" w='full' alignItems="flex-end" justifyContent={"flex-end"}>
          <FormLabel fontWeight={"bold"} htmlFor="email-alerts" mb="0">
            DECLENCHER UNE MAINTENANCE 
          </FormLabel>
          <Switch id="email-alerts" />
        </FormControl>
      </HStack>
    </VStack>
  );
}
