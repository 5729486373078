import create from "zustand";
import { Reward, User, UserStatistic } from "../interface/types";


interface UserReward {
    rewards : any,
    setRewards : (value : Reward)=> void
}
interface Users extends UserReward{
    users : {},
    setUsers : (value : User )=> void,
}
interface ILevelData {
    usersCountByLevel: number;
}
interface UserLevels {
    levels: { [key: number]: ILevelData }
    setLevel: (index: number, lvl: ILevelData) => any
}
interface UsersCount extends UserLevels {
    usersCount : any,
    setUsersCount : (value : UserStatistic)=> void
}

  export const globalStore = create<Users>(set =>({
      users : {},
      setUsers: (value) => set({ users: value }),
      rewards : undefined,
      setRewards : (value) => set({rewards: value })
     
  }))



  export const useStatistics = create<UsersCount>(set =>({
    usersCount : undefined,
    setUsersCount : (value) => set({usersCount: value }),
    levels: {},
    setLevel(index, lvl) {
        set((s) => {
            let levels = {...s.levels};
            return {
                ...s,
                levels: {
                    ...levels,
                    [index]: lvl
                }
            } 
        });
    },
  }))
 