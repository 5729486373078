import { useQuery } from '@apollo/client';
import React,{useEffect,useState,PropsWithChildren} from 'react';
import {  FETCHALL_USER, GET_REWARD, GET_USER_BYLEVEL } from '../core/req';
import { globalStore, useStatistics } from '../store/store';
type Props = PropsWithChildren<{}>;

export default function Initializer({ children }: Props) {
const rewardData = useQuery(GET_REWARD)
const userCountData = useQuery(FETCHALL_USER)
const {setUsers,setRewards} = globalStore()
const {setUsersCount,setLevel} = useStatistics()
const [fetchedIndex, setFetchedIndex] = useState(0);

const levelQuery = useQuery(GET_USER_BYLEVEL, {
    variables: {
      level: fetchedIndex,
    },
  });


useEffect(() => {
   let token = localStorage.getItem("w-token")
   if(token && !rewardData.loading && rewardData.data){
       setRewards(rewardData.data.rewards)
   }
}, [rewardData,setRewards])

useEffect(() => {
    let token = localStorage.getItem("w-token")
    if(token && !userCountData?.loading && userCountData?.data){
        setUsersCount(userCountData.data.usersCount)
    }
 }, [rewardData, setRewards, setUsersCount, userCountData.data, userCountData?.loading])

 useEffect(() => {
    if (localStorage.getItem("w-token") && !levelQuery.loading && levelQuery?.data) {
      setLevel(fetchedIndex, levelQuery.data.usersCountByLevel);
      if (fetchedIndex >= 3) {
        return;
      } else {
        setFetchedIndex((previous) => previous + 1);
      }
    }
  }, [fetchedIndex, levelQuery.data, levelQuery.loading, setLevel]);


  return (
    <div>
      {children}
    </div>
  );
}
