import { Stack, useBreakpointValue, VStack } from '@chakra-ui/react'
import React from 'react'
import Content from './Content'
import NavBar from './NavBar'
import SideBar from './SideBar'

export default function Layout() {

  const webMobile = useBreakpointValue({ base: false, lg: true })
 

  return (
    <Stack display="flex" minH='100vh' flexDirection={"row"} spacing={0} >
      {webMobile && <VStack w='240px' bg='#ddd'>
        <SideBar />
      </VStack>}
      <VStack flexGrow={1} bg='#ddd'>
        <NavBar />
        <Content />
      </VStack>
    </Stack>
  )
}
