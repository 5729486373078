import React from 'react';
import {
    Grid,
    GridItem,
    HStack,
    VStack,
    Box,
    Input,
    Button,
    Text,
    useBreakpointValue,
  } from "@chakra-ui/react";
  import { MagnifyingGlass } from 'phosphor-react';
import SearchUser from './SearchUser';

export default function Users() {
    

  return (
    <VStack w='full' bg="#ddd">
       <SearchUser />
     
    </VStack>
  );
}
