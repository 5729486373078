import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  useToast,
  Input,
  Textarea,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { nanoid } from 'nanoid';
import { useMutation } from "@apollo/client";
import React, { useState,useCallback } from "react";
import { ADD_EVENT } from "../core/req";

export default function AddEvent() {
  const code = nanoid()
    const toast = useToast();
    const width = useBreakpointValue({base:'95vw',lg:'50vw'})
  const [AddEvent, { loading }] = useMutation(ADD_EVENT);
  const [event, setEvent] = useState({
    title: "",
    code: code,
    description: "",
    from: "",
    to: "",
    data: {},
  });
const addEvent =  useCallback(
      async() => {
        const eventRequest = await AddEvent({
            variables: { data: event },
          });
          if (eventRequest.data.createEvent) {
            toast({
              title: "Evènement ajoute avec succès",
              description: "Vous venez d'ajouter un évènement",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          //  window.location.reload()
          } else {
            toast({
              title: "Echec d'ajout de l'évènement",
              description: "Une erreur s'est produite !",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
      },
      [AddEvent, event, toast],
  )
  return (
    <VStack
      w="full"
      alignItems={"center"}
      bg="primary.50"
      justifyContent="flex-start"
    >
      <VStack w={width}  my={16} bg="#fff" rounded="lg" minH="60vh">
        <VStack w="90%" py={4} spacing={4}>
          <FormControl>
            <FormLabel>Titre</FormLabel>
            <Input
              value={event.title}
              onChange={(e: any) => {
                setEvent({ ...event, title: e.target.value });
              }}
              type="text"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea value={event.description} onChange={(e: any) => {
                setEvent({ ...event, description: e.target.value });
              }} />
          </FormControl>
          <FormControl>
            <FormLabel>Date de début</FormLabel>
            <Input value={event.from} onChange={(e: any) => {
                setEvent({ ...event, from: e.target.value });
              }} type="date" />
          </FormControl>
          <FormControl>
            <FormLabel>Date de fin</FormLabel>
            <Input value={event.to} onChange={(e: any) => {
                setEvent({ ...event, to: e.target.value });
              }} type="date" />
          </FormControl>
          <HStack w="full">
            <Button bg="blue.400" onClick={addEvent} color="#fff" colorScheme={"blue"}>
              Ajouter
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  );
}
