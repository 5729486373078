/* eslint-disable jsx-a11y/alt-text */
import {
  VStack,
  Text,
  useBreakpointValue,
  Input,
  HStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  CircleWavyCheck,
  EnvelopeSimple,
  CircleWavyWarning,
  PencilSimple,
  Phone,
  UserCircle,
  Flag,
} from "phosphor-react";
import { useQuery } from "@apollo/client";
import { FIND_USER_BY_CODE } from "../core/req";
import { useParams } from "react-router-dom";

export default function User() {
  let { id } = useParams();
  const position = useBreakpointValue({ base: "column", lg: "row" });

  const findQuery = useQuery(FIND_USER_BY_CODE, {
    variables: {
      data: {
        searchReferralCode: id,
      },
    },
  });

  return (
    <VStack w="full" bg="#ddd" p={2} spacing={8}>
      <VStack w="full" alignItems={"flex-start"}>
        <HStack>
          <Text fontWeight={"bold"}>Informations personnelles</Text>
        </HStack>
        {findQuery?.data?.users.map((data: any, i: any) => {
          return (
            <VStack
              key={i}
              w="full"
              bg="#fff"
              p={8}
              spacing={8}
              rounded="10px"
              boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
              minH="100px"
            >
              <VStack
                w="full"
                display="flex"
                flexDirection={position as any}
                spacing={4}
              >
                <HStack w="full" spacing={4}>
                  <UserCircle size={24} />
                  <Input
                    type="text"
                    readOnly
                    value={`${data?.firstName} ${data?.lastName}`}
                    placeholder="Nom complet"
                    variant={"flushed"}
                    border="none"
                  />
                </HStack>
                <HStack w="full" spacing={0}>
                  <img
                    src={`https://countryflagsapi.com/png/${data?.country}`}
                    height="24px"
                    width="24px"
                  />
                  <Input
                    type="text"
                    readOnly
                    value={`${data?.countryName}`}
                    placeholder="Pays"
                    border="none"
                  />
                </HStack>
              </VStack>

              <HStack w="full" spacing={4}>
                <EnvelopeSimple size={24} />
                <Input
                  type="text"
                  readOnly
                  value={`${data?.email}`}
                  placeholder="Adresse mail"
                  variant={"flushed"}
                  border="none"
                />
              </HStack>
              <VStack
                w="full"
                display="flex"
                flexDirection={position as any}
                spacing={4}
              >
                <HStack w="full">
                  <Phone size={24} color="green" />
                  <Input
                    type="text"
                    readOnly
                    value={`${data?.moovNumber}`}
                    placeholder="Numero Moov"
                    variant={"flushed"}
                    border="none"
                  />
                </HStack>
                <HStack w="full">
                  <Phone size={24} color="orange" />
                  <Input
                    type="text"
                    readOnly
                    value={`${data?.mtnNumber}`}
                    placeholder="Numero MTN"
                    variant={"flushed"}
                    border="none"
                  />
                </HStack>
              </VStack>
            </VStack>
          );
        })}
      </VStack>

      <VStack w="full" alignItems={"flex-start"}>
        <HStack>
          <Text fontWeight={"bold"}>Informations du compte</Text>
        </HStack>
        {findQuery?.data?.users.map((data: any, i: any) => {
          const day = new Date(parseInt(data?.createdAt)).getDate();
          const month = new Date(parseInt(data?.createdAt)).getMonth();
          const year = new Date(parseInt(data?.createdAt)).getFullYear();
          return (
            <VStack
              w="full"
              bg="#fff"
              p={8}
              spacing={8}
              rounded="10px"
              boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
              minH="100px"
            >
              <HStack w="full" spacing={4}>
                <Text>Date d'inscription :</Text>
                <Text fontWeight={"bold"}>
                  {day} / {month} / {year}
                </Text>
              </HStack>
              <HStack w="full" spacing={4}>
                <Text>Statut du compte :</Text>

                {data?.activated && (
                  <HStack>
                    <Text color="green" fontWeight={"bold"}>
                      activé
                    </Text>
                    <CircleWavyCheck size={24} color="green" />
                  </HStack>
                )}
                {!data?.activated && (
                  <HStack>
                    <Text color="red" fontWeight={"bold"}>
                      non activé
                    </Text>
                    <CircleWavyWarning color="red" size={24} />
                  </HStack>
                )}
              </HStack>
              <HStack w="full" spacing={4}>
                <Text>Parrain :</Text>
                {data?.parent && (
                  <Text fontSize={"sm"} fontWeight={"bold"}>
                    {data?.parent?.firstName} {data?.parent?.lastName}
                  </Text>
                )}
                {!data?.parent && (
                  <Text fontSize={"sm"} color="blue.400" fontWeight={"bold"}>
                    Premier parrain du réseau
                  </Text>
                )}
              </HStack>
              <HStack w="full" spacing={4}>
                <Text>Code de parrainage :</Text>
                <Text fontWeight={"bold"}>{data?.referralCode}</Text>
              </HStack>
            </VStack>
          );
        })}
      </VStack>

      <VStack w="full" alignItems={"flex-start"}>
        <HStack>
          <Text fontWeight={"bold"}>Information réseau parrainage</Text>
        </HStack>
        {findQuery?.data?.users.map((data:any,i:any)=>{
          return <VStack
          w="full"
          bg="#fff"
          p={8}
          spacing={8}
          rounded="10px"
          boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
          minH="100px"
        >
          <HStack w="full" spacing={4}>
            <Text>Niveau actuel: </Text>
            <Text fontWeight={"bold"}>{data?.level}</Text>
          </HStack>
          <HStack w="full" spacing={4}>
            <Text>Nombre total de filleuls:</Text>
            <Text fontWeight={"bold"}>{data?.totalChildrenCount}</Text>
          </HStack>
        </VStack>
        })}
      </VStack>
    </VStack>
  );
}
