import { ApolloClient,createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';


const httpLink = createHttpLink({

  uri: 'https://events-office.jmhvbusiness.com/api/',

});
const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('w-token');
  
    return {
      headers: {
        ...headers,
        Authorization: "bearer " + token
      }
  
    }
  
  });

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
})


export default client