/* eslint-disable jsx-a11y/alt-text */
import { Box,Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Eye, Article, User, Money, SpeakerHigh, Ticket, Table, Trophy, Wrench, Alarm, FileSearch, MagnifyingGlass, UserPlus } from 'phosphor-react';
import styles from "../styles/layout.module.css"
import  Logo  from "../img/logo.png"

export default function SideBar() {
const webMobile = useBreakpointValue({base:false,lg:true})

    return (
        <VStack h='full' w='full' minH='100vh'  py={8} spacing={8} bg="green.900" >
            {webMobile && <>
                <Box  bg="#fff" p={2} rounded="full"  justifyContent={"flex-start"}><img src={Logo} height={"40px"} width="40px" /></Box>
            <VStack w="full"    alignItems="flex-start" spacing={0}>
                <NavLink to="/home" className={({ isActive }) => isActive ? styles['homeLink'] : styles['hoverLink']}>
                    <Table size={18} />
                    <Text color='primary.50' fontSize={"sm"} ml={4}>TABLEAU DE BORD</Text>
                </NavLink>

                <NavLink to="/home/users" className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                    <MagnifyingGlass size={18} />
                    <Text color='primary.50' fontSize={"sm"} ml={4}>RECHERCHER</Text>
                </NavLink>

                <NavLink to="/home/signup" className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                    <UserPlus size={18} />
                    <Text color='primary.50' fontSize={"sm"} ml={4}>INSCRIPTION</Text>
                </NavLink>

                <NavLink to="/home/winners" className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                    <User size={18} />
                    <Text color='primary.50' fontSize={"sm"} ml={4}>GAGNANTS</Text>
                </NavLink>

                <NavLink to="/home/packs" className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                    <Trophy size={18} />
                    <Text color='primary.50' fontSize={"sm"} ml={4}>RECOMPENSES</Text>
                </NavLink>

                <NavLink to="/home/payment" className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                    <Money size={18} />
                    <Text color='primary.50' fontSize={"sm"} ml={4}>PAIEMENTS</Text>
                </NavLink>

                <NavLink to="/home/events" className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                    <Alarm size={18} />
                    <Text color='primary.50' fontSize={"sm"} ml={4}>EVENEMENTS</Text>
                </NavLink>

                <NavLink to="/home/feeds" className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']} >
                    <SpeakerHigh size={18} />
                    <Text color='primary.50' fontSize={"sm"} ml={4}>ANNONCES</Text>
                </NavLink>

                <NavLink to="/home/settings" className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']} >
                    <Wrench size={18} />
                    <Text color='primary.50' fontSize={"sm"} ml={4}>REGLAGES</Text>
                </NavLink>

            </VStack>
            </>}
        </VStack>
    );
}
