import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  useDisclosure,
  Box,
  Button,
  Badge,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_FEED, GET_FEED } from "../core/req";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { TrashSimple } from "phosphor-react";

export default function FeedsList() {
  const feed = useQuery(GET_FEED);
  const toast = useToast()
  const [feedId, setFeedId] = useState("")
  const [DeleteFeed] = useMutation(DELETE_FEED);
  const deletor = useDisclosure()
  function formatter(date: any) {
    let newDate = new Date(parseInt(date));
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const deleteFeed =(id:string)=>{
    setFeedId(id);
    deletor.onOpen()
    }

    const deleteFeedConfirm = async()=>{
        const feedResult = await DeleteFeed({
            variables: {
                deleteFeedId: feedId,
            },
          });
          console.log(feedResult);
          deletor.onClose()
          if (feedResult.data.deleteFeed.done) {
              toast({
                title: "Suppression avec succès",
                description: "Vous venez de supprimer cette récompense",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              window.location.reload()
            } else {
              toast({
                title: "Echec de la supression",
                description: "Une erreur s'est produite !",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
    }
  return (
    <VStack w="full" px={4}>
      {feed?.data?.feeds.length === 0 && (
        <HStack
          w="90%"
          borderLeft={"10px solid #f25a2b"}
          rounded="lg"
          bg="#fff"
          boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
          my={8}
          h="80px"
        >
          <Text px={8}>aucune annonce faite</Text>
        </HStack>
      )}
      {feed?.data?.feeds.map((data: any, i: any) => {
        let dateTo = formatter(data.createdAt);
        return (
          <VStack
            w="full"
            key={i}
            px={2}
            alignItems="flex-start"
            justifyContent={"flex-start"}
            py={4}
            bg="blue.100"
            rounded="lg"
            border="1px solid #ddd"
          >
            <HStack w="full" justifyContent={"space-between"}>
              <Box>
                <Text textTransform={"uppercase"} fontWeight={"bold"}>
                  {data.title}
                </Text>
              </Box>
              <HStack>
                <Button size="sm" bg="#fff">
                  modifier
                </Button>
                <Button size="sm" onClick={()=>deleteFeed(data.id)} colorScheme={"orange"} bg="red.600">
                  <TrashSimple size={18} color="#fff" />
                </Button>
              </HStack>
            </HStack>
            <VStack
              w="full"
              alignItems="flex-start"
              justifyContent={"flex-start"}
            >
              <Text dangerouslySetInnerHTML={{
                  __html: `${data.message}`,
                }}
              ></Text>
            </VStack>
            <HStack w="full">
              <Badge bg="red.400" color="#fff">
                {dateTo}
              </Badge>
            </HStack>
          </VStack>
        );
      })}

<Modal isOpen={deletor.isOpen} onClose={deletor.onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="primary.50" m={2}>
          <ModalHeader fontSize="sm">Supprimer l'évenement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Voulez-vous vraiment retirer cet évenement ?</Text>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr={3} onClick={deletor.onClose}>
              annuler
            </Button>
            <Button size="sm" onClick={deleteFeedConfirm} colorScheme={"red"} bg="red.600" color="#fff">
              supprimer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
