/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../img/logo.png"
import Raillure from "../img/raillure.svg"
import {
  VStack,
  useToast,
  Button,
  Box,
  Text,
  useBreakpointValue,
  Center,
  HStack,
  Container,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { CONNECT_USER } from "../core/req";
import { useMutation } from "@apollo/client";


export default function Login() {
  const toast = useToast();
  const [ConnectUser, { loading }] = useMutation(CONNECT_USER);
  const navigate = useNavigate()
  const width = useBreakpointValue({ base: "95%", lg: "40%" });
  const wdt = useBreakpointValue({ base: "90%", lg: "80%" });
  const [user, setUser] = useState({
    login: "",
    password: "",
  });
  const onSubmit = useCallback(async () => {
    try {
      const userData = await ConnectUser({
        variables: { data: user },
      });
      if (userData.data?.signin.__typename === "SigninSuccess") {
        localStorage.setItem("w-token", userData.data?.signin.token);
        window.location.assign("/home");
        return;
      }
      throw new Error("Un problème est survenu.");
    } catch (error) {
      toast({
        title: "Erreur de connexion",
        description: "Email ou mot de passe incorrecte.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [ConnectUser, user, toast]);

 
  return (
    <VStack
      bg="green.50"
      w="full"
      minH="100vh"
      justifyContent={"center"}
    >
      <VStack
        mx={2}
        w={width}
        fontFamily="Montserrat"
        spacing={8}
        minH="50vh"
        py={8}
        bg="green.900"
        color="primary.50"
        rounded="xl"
      >
        <Link to="/">
          <HStack w="full" p={2} bg="#fff" rounded="full" justifyContent={"center"}>
            <img src={Logo} height={"70px"} width="70px" />
          </HStack>
        </Link>
        <Box>
          <Text fontWeight={"700"}>Bienvenue sur l'espace d'administration !</Text>
        </Box>
        <VStack w="full" spacing={8}>
          <FormControl w={wdt}>
            <FormLabel>Adresse email</FormLabel>
            <Input
              bg="#fff"
              value={user.login}
              type="email"
              color="primary.900"
              onChange={(e: any) => {
                setUser({...user,login:e.target.value});
              }}
            />
          </FormControl>
          <FormControl w={wdt}>
            <FormLabel>Mot de passe</FormLabel>
            <Input
              bg="#fff"
              color="primary.900"
              value={user.password}
              type="password"
              onChange={(e: any) => {
                setUser({...user,password:e.target.value});
              }}
            />
          </FormControl>
          <FormControl w={wdt}>
            <Button 
              colorScheme={"orange"}
              onClick={onSubmit}
              isLoading={loading}
              bg="primary.500"
              color="primary.50"
            >
              CONNEXION
            </Button>
          </FormControl>
          
        </VStack>
      </VStack>
    </VStack>
  );
}
