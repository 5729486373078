import { extendTheme, theme } from "@chakra-ui/react"

export const baseTheme = extendTheme({
    fonts: {
        heading: 'Poppins',
        body: 'Work Sans'
    },
    colors:{
        primary: 
        {
            50: '#ffe9e0',
            100: '#ffc5b3',
            200: '#faa185',
            300: '#f67b56',
            400: '#f25727',
            500: '#d83d0d',
            600: '#a92f09',
            700: '#792105',
            800: '#4b1100',
            900: '#1f0300',
          }
    }


})