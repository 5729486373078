import React from "react";
import {
  Grid,
  GridItem,
  HStack,
  VStack,
  Box,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {Trophy, UsersThree} from "phosphor-react"
import LevelStat from "./LevelStat";
import { globalStore, useStatistics } from "../store/store";

export default function Statistics() {
  const {rewards} =  globalStore()
  const {usersCount} = useStatistics()
  const position = useBreakpointValue({ base: "column", lg: "row" });
 
  return (
    <VStack w="full" bg="#ddd" py={8} px={4}>
      <Grid
        w="full"
        display="flex"
        flexDirection={position as any}
        templateColumns="repeat(2, 1fr)"
        gap={6}
      >
        <GridItem w="100%" p={4} color="#fff" rounded="lg" bg="green.400">
          <HStack w='full' justifyContent={"flex-start"}>
            <Text fontSize={"sm"} fontWeight="bold">NOMBRE D'UTILISATEUR</Text>
          </HStack>
          <HStack w='full' justifyContent={"space-between"}>
            <Text fontSize={"6xl"}>{usersCount}</Text>
            <UsersThree size={24} />
          </HStack>
        </GridItem>
        <GridItem w="100%" color="#fff" p={4} rounded="lg" bg="blue.400">
          <HStack w='full' justifyContent={"flex-start"}>
            <Text fontSize={"sm"} fontWeight="bold">NOMBRE DE NIVEAU DE RECOMPENSE</Text>
          </HStack>
          <HStack w='full' justifyContent={"space-between"}>
            <Text fontSize={"6xl"}>{rewards?.length}</Text>
            <Trophy size={24} />
          </HStack>
        </GridItem>
       
      </Grid>
      
      <LevelStat />
    </VStack>
  );
}
