/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback } from "react";
import {
  Grid,
  GridItem,
  HStack,
  VStack,
  Box,
  Input,
  Button,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import QRCode from "react-qr-code";
import { MagnifyingGlass } from "phosphor-react";
import { useQuery } from "@apollo/client";
import { FIND_USER_BY_CODE } from "../core/req";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";

export default function SearchUser() {
  const width = useBreakpointValue({ base: "100%", lg: "30%" });
  const device = useBreakpointValue({ base: true, lg: false });
  const [FindUserInput, setFindUserInput] = useState("");
  const [resultData, setResultData] = useState<any[]>([]);
  const findQuery = useQuery(FIND_USER_BY_CODE, {
    variables: {
      data: {
        searchReferralCode: FindUserInput,
      },
    },
  });

  const findData = useCallback(async () => {
    setResultData(findQuery?.data.users);
  }, [findQuery]);
  return (
    <VStack w="full" bg="#ddd" p={2}>
      <HStack
        w="full"
        p={8}
        justifyContent="flex-end"
        borderBottom={"1px solid #fff"}
      >
        <Input
          w={width}
          value={FindUserInput.toUpperCase()}
          bg="#fff"
          onChange={(e: any) => setFindUserInput(e.target.value)}
          py={6}
          placeholder="Entrez un code de parrainage"
        />
        <Button p={6} onClick={findData} colorScheme="orange" bg="primary.200">
          <MagnifyingGlass color="#fff" size={24} />
        </Button>
      </HStack>

      {FindUserInput && resultData.length === 0 && (
        <HStack
          bg="#fff"
          textAlign={"center"}
          m={8}
          rounded="lg"
          justifyContent={"center"}
          p={8}
        >
          <Text px={4}>Oups ! aucun utilisateur n'a ce code de parrainage</Text>
        </HStack>
      )}
      {resultData.length !== 0 &&
        resultData.map((data, i) => {
          let date = new Date(parseInt(data.createdAt));
          let day = date.getDay();
          let month = date.getMonth();
          let year = date.getFullYear();
          return (
            <HStack
              key={i}
              w="full"
              boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
              p={4}
              justifyContent="space-between"
              bg="#fff"
              rounded="lg"
            >
              <HStack p={2} bg="blue.400" justifyContent={"flex-start"}>
                <Text color="#fff">{data?.referralCode as any}</Text>
              </HStack>
              {!device && (
                <HStack w="full" justifyContent={"flex-start"}>
                  <Text>{data?.lastName as any}</Text>
                </HStack>
              )}
              <HStack w="full" justifyContent={"flex-start"}>
                <Text>{data?.firstName as any}</Text>
              </HStack>
              {!device && (
                <HStack w="full" justifyContent={"flex-start"}>
                  <Text>{data?.email as any}</Text>
                </HStack>
              )}

              <HStack w="full" justifyContent={"flex-end"}>
                <Link to={`/home/users/${data?.referralCode}`}>
                  <Button
                    w="full"
                    bg="blue.400"
                    colorScheme={"green"}
                    color="#fff"
                  >
                    plus d'info
                  </Button>
                </Link>
              </HStack>
            </HStack>
          );
        })}
    </VStack>
  );
}
