import React from "react";
import {
  Grid,
  GridItem,
  HStack,
  VStack,
  Button,
  Box,
  Input,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

export default function Winners() {
  const width = useBreakpointValue({ base: "" });
  return (
    <VStack w="full" bg="primary.50" px={4}>
      <HStack
        w="full"
        textAlign={"center"}
        justifyContent={"center"}
        bg="blue.400"
        color="#fff"
        py={4}
        rounded="lg"
      >
        <Text fontWeight={"bold"} fontSize="xl" letterSpacing={4}>
          LISTE DES GAGNANTS
        </Text>
      </HStack>
      <Tabs w='full' rounded="lg" bg="#fff" variant="unstyled">
        <TabList w='full'>
          <Tab w='full' roundedTopLeft={"8px"}  _selected={{ color: "black", bg: "#ddd" }}>NIVEAU 1</Tab>
          <Tab w='full' _selected={{ color: "white", bg: "yellow.600" }}>NIVEAU 2</Tab>
          <Tab w='full' roundedTopRight={"8px"} _selected={{ color: "white", bg: "#292929" }}>NIVEAU 3</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <p>Liste non disponible</p>
          </TabPanel>
          <TabPanel>
            <p>Liste non disponible</p>
          </TabPanel>
          <TabPanel>
            <p>Aucun gagnant pout l'instant</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
