import React from 'react';
import {
    Grid,
    GridItem,
    HStack,
    VStack,
    Button,
    Box,
    Input,
    Heading,
    Text,
    useBreakpointValue,
  } from "@chakra-ui/react";
  import { Link } from 'react-router-dom';
import FeedsList from './FeedsList';

export default function Feeds() {
  return (
    <VStack w="full" bg="#ddd">
    <HStack w="full" justifyContent={"flex-end"} p={6}>
      <Link to="/home/addfeed">
        <Button
          size="sm"
          colorScheme={"transparent"}
          _hover={{ bg: "orange" }}
          color="primary.50"
          bg="primary.900"
        >
          DIFFUSER UNE INFORMATION
        </Button>
      </Link>
    </HStack>
   <FeedsList />
  </VStack>
  );
}
