import React from "react";
import { HStack, VStack, Text, Button } from "@chakra-ui/react";
import { Timer } from "phosphor-react";
import { Link } from "react-router-dom";
import EventsList from "./EventsList";

export default function Evenements() {
  return (
    <VStack w="full">
      <VStack w="full">
        <HStack
          w="full"
          p={4}
          bg="#ddd"
          justifyContent={"space-between"}
          rounded="lg"
        >
          <Text>Ajouter un évènement</Text>
          <Link to="/home/addevent">
            <Button bg="blue.400" colorScheme={"blue"}>
              <Timer size={24} color="#fff" />
            </Button>
          </Link>
        </HStack>
      </VStack>
      <EventsList />
    </VStack>
  );
}
