import {
  Grid,
  GridItem,
  HStack,
  VStack,
  Button,
  Box,
  Input,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { globalStore } from "../store/store";
import Rewards from "./Rewards";

export default function Packs() {
  const { rewards } = globalStore();
  return (
    <VStack w="full" bg="#ddd">
      <HStack w="full" justifyContent={"flex-end"} p={4}>
        <Link to="/home/addpack">
          <Button
            size="sm"
            disabled={rewards?.length === 3 ? true : false}
            colorScheme={"transparent"}
            _hover={{ bg: "orange" }}
            color="primary.50"
            bg="primary.900"
          >
            AJOUTER UNE RECOMPENSE
          </Button>
        </Link>
      </HStack>
      <Rewards />
      {rewards?.length === 0 && (
        <HStack
          w="90%"
          borderLeft={"10px solid #f25a2b"}
          rounded="lg"
          bg="#fff"
          boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
          my={8}
          h="80px"
        >
          <Text px={8}>aucune récompense disponible</Text>
        </HStack>
      )}
    </VStack>
  );
}
