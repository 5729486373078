/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Badge,
  Checkbox,
  CheckboxGroup,
  FormHelperText,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import moov from "../img/moov.jpg";
import mtn from "../img/mtn.jpg";
import {
  VStack,
  Button,
  Box,
  Text,
  Toast,
  useBreakpointValue,
  Center,
  HStack,
  Container,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import styles from "../../styles/Home.module.css";
import { Plus, X } from "phosphor-react";
import { CREATE_USER } from "../core/req";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

export default function SignUp() {
  const navigate = useNavigate()
  const width = useBreakpointValue({ base: "100%", lg: "60%" });
  const toast = useToast();
  const wdt = useBreakpointValue({ base: "100%", lg: "100%" });
  const position = useBreakpointValue({ base: "column", lg: "row" });
  const [reseau, setReseau] = useState<String>("");
  let [line, setLine] = React.useState<any>([]);
  const [cpassword, setCpassword] = useState("");
  const [country, setCountry] = useState<String>("BJ");
  const [iso, setIso] = useState([]);
  const [countries, setCountries] = useState([]);
  const countryList = require("country-list");
  const [bool, setBool] = useState<Boolean>(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    moovNumber: "",
    mtnNumber: "",
    parentReferralCode: "",
    password: "",
  });

  React.useEffect(() => {
    setCountries(countryList.getNames());
    setIso(countryList.getCodes());
  }, [countryList]);
  const [AddUser, { data, loading }] = useMutation<any>(CREATE_USER, {
    fetchPolicy: "network-only",
  });

  const newUser = useCallback(async () => {
    let result = await AddUser({
      variables: { data: user },
    });
    console.log(result.data);
    try {
      if (result.data?.adminSignup?.user?.id) {
        toast({
          title: "Inscription réussie",
          description: "votre compte a été créé !",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        return;
      }
      throw new Error("L'inscription a échoué !");
    } catch (error) {
      toast({
        title: "Echec de l'inscription !",
        description: `${result.data?.adminSignup?.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [user]);

  return (
    <VStack
      background="#fff"
      py={1}
      w="full"
      backgroundPosition="center"
      fontFamily={"Montserrat"}
      backgroundRepeat="no-repeat"
      minH="100vh"
      justifyContent={"center"}
    >
      <VStack
        mx={2}
        px={8}
        w={width}
        fontFamily="Montserrat"
        spacing={4}
        py={8}
        rounded="xl"
      >
       
        <VStack w="full" spacing={8}>
          <HStack
            w="full"
            spacing={0}
            display="flex"
            flexDirection={position as any}
          >
            <FormControl w={wdt} m={2}>
              <FormLabel>Nom *</FormLabel>
              <Input
                value={user.lastName}
                required
                onChange={(e: any) => {
                  setUser({ ...user, lastName: e.target.value });
                }}
              />
              {!user.lastName && (
                <FormHelperText fontSize={"12px"} color="red">
                  votre nom est requis*
                </FormHelperText>
              )}
            </FormControl>
            <FormControl w={wdt} m={2}>
              <FormLabel>Prénoms *</FormLabel>
              <Input
                required
                value={user.firstName}
                onChange={(e: any) => {
                  setUser({ ...user, firstName: e.target.value });
                }}
              />
              {!user.firstName && (
                <FormHelperText fontSize={"12px"} color="red">
                  votre prénom est requis*
                </FormHelperText>
              )}
            </FormControl>
          </HStack>
          <HStack
            w="full"
            spacing={0}
            display="flex"
            flexDirection={position as any}
          >
            <FormControl w={wdt} m={2}>
              <FormLabel>Sélectionnez votre pays (facultatif)</FormLabel>
              <Select
                disabled={line.length === 2 ? true : false}
                onChange={(e: any) =>
                  setCountry(countryList.getCode(e.target.value))
                }
              >
                {countries.map((data, i) => {
                  return (
                    <option key={i} value={data} style={{ margin: "8px" }}>
                      <span style={{ margin: "8px" }}>
                        {getUnicodeFlagIcon(countryList.getCode(data))}
                      </span>
                      <span>{data}</span>
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </HStack>
          <HStack
            w="full"
            spacing={0}
            display="flex"
            flexDirection={position as any}
          >
            <FormControl w={wdt} m={2}>
              <FormLabel>Adresse email *</FormLabel>
              <Input
              
                value={user.email}
                required
                type="email"
                onChange={(e: any) => {
                  setUser({ ...user, email: e.target.value });
                }}
              />
              {!user.email && (
                <FormHelperText fontSize={"12px"} color="red">
                  votre email est requis*
                </FormHelperText>
              )}
            </FormControl>
            <FormControl w={wdt} m={2}>
              <FormLabel>Code du parrain *</FormLabel>
              <Input
                value={user.parentReferralCode}
                onChange={(e: any) => {
                  setUser({
                    ...user,
                    parentReferralCode: e.target.value.toUpperCase(),
                  });
                }}
              />
              {!user.parentReferralCode && (
                <FormHelperText fontSize={"12px"} color="red">
                  le code parrain est obligatoire*
                </FormHelperText>
              )}
              
            </FormControl>
          </HStack>
          <HStack
            w="full"
            spacing={0}
            display="flex"
            flexDirection={position as any}
          >
            <FormControl w={wdt} m={2}>
              <FormLabel>Numéro Flooz</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={
                    <img src={moov} height={"24px"} width="24px" />
                  }
                />
                <Input
                  required
                  value={user.moovNumber}
                  onChange={(e: any) => {
                    setUser({ ...user, moovNumber: e.target.value });
                  }}
                  placeholder=""
                />
              </InputGroup>
            </FormControl>

            <FormControl w={wdt} m={2}>
              <FormLabel>Numéro Momo</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<img src={mtn} height={"24px"} width="24px" />}
                />
                <Input
                  required
                  value={user.mtnNumber}
                  onChange={(e: any) => {
                    setUser({ ...user, mtnNumber: e.target.value });
                  }}
                  placeholder=""
                />
              </InputGroup>
            </FormControl>
          </HStack>

          <HStack
            w="full"
            spacing={0}
            display="flex"
            flexDirection={position as any}
          >
            <FormControl w={wdt} m={2}>
              <FormLabel>Mot de passe *</FormLabel>
              <Input
                type="password"
                value={user.password}
                required
                onChange={(e: any) => {
                  setUser({ ...user, password: e.target.value });
                }}
              />
              {!user.password && (
                <FormHelperText fontSize={"12px"} color="red">
                  mot de passe requis (08 caractères minimum)*
                </FormHelperText>
              )}
            </FormControl>
            <FormControl w={wdt} m={2}>
              <FormLabel>Confirmez mot de passe *</FormLabel>
              <Input
                value={cpassword}
                required
                type="password"
                onChange={(e: any) => {
                  setCpassword(e.target.value);
                }}
              />
              {user.password !== cpassword && (
                <FormHelperText fontSize={"12px"} color="red">
                  incorrecte (08 caractères minimum)!
                </FormHelperText>
              )}
            </FormControl>
          </HStack>
          
          <FormControl w={wdt}>
            <Button
              m={2}
              colorScheme={"orange"}
              isLoading={loading}
              onClick={newUser}
              disabled={
                user.password === cpassword && bool === true ? false : true
              }
              bg="primary.500"
              color="primary.50"
            >
              REJOINDRE LE CLUB
            </Button>
          </FormControl>
         
        </VStack>
      </VStack>
    </VStack>
  );
}
