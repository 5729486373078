import React,{useState} from "react";
import { HStack, VStack,Input, Text,useDisclosure, Box, Button, Badge, useToast, FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { useQuery,useMutation } from "@apollo/client";
import { DELETE_EVENT, EDIT_EVENT, GET_EVENT } from "../core/req";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from "@chakra-ui/react";
import { TrashSimple } from "phosphor-react";

export default function EventsList() {
  const events = useQuery(GET_EVENT);
  const [DeleteEvent] = useMutation(DELETE_EVENT);
  const [eventId, setEventId] = useState("")
  const deletor = useDisclosure();
  const edit = useDisclosure();
  const [editData, setEditData] = useState<any>();
  const toast = useToast()
  const [EditEvent, { loading }] = useMutation(EDIT_EVENT);
  const [event, setEvent] = useState({
    title: "",
    code: editData?.code,
    description: "",
    from: "",
    to: "",
    data: {},
  });

  function formatter(date: any) {
    let newDate = new Date(parseInt(date));
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    return `${day}/${month}/${year}`;
  }
const deleteEvent =(id:string)=>{
setEventId(id);
deletor.onOpen()
}

const updateEvent = (id:any)=>{
  setEventId(id);
  const editEventData = events?.data?.events.find((e: any) => e.id === id);
  setEditData(editEventData)
  edit.onOpen()
}

const editConfirmEvent = React.useCallback(
  async() => {
    const editResult = await EditEvent({
      variables: {
        updateEventId: eventId,
        data: {
          title: event?.title,
          description: event?.description,
          code: event?.code || editData?.code,
          from:  event.from,
          to: editData?.to
        },
      },
    });
  
  },
  [EditEvent, editData?.code, editData?.to, event?.code, event?.description, event.from, event?.title, eventId],
)
console.log(event.title);
const deleteEventConfirm = async()=>{
    const eventData = await DeleteEvent({
        variables: {
            deleteEventId: eventId,
        },
      });
      console.log(eventData);
      deletor.onClose()
      if (eventData.data.deleteEvent) {
         
          toast({
            title: "Suppression avec succès",
            description: "Vous venez de supprimer cette récompense",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          window.location.reload()
        } else {
          toast({
            title: "Echec de la supression",
            description: "Une erreur s'est produite !",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
}
  return (
    <VStack w="full" py={8} px={4} bg="#ddd">
      {events?.data?.events?.length === 0 && (
        <HStack
          w="90%"
          borderLeft={"10px solid #f25a2b"}
          rounded="lg"
          bg="#fff"
          boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
          my={8}
          h="80px"
        >
          <Text px={8}>aucun évènement programmé</Text>
        </HStack>
      )}
      {events?.data?.events.map((data: any, i: any) => {
        let dateTo = formatter(data.to);
        let dateFrom = formatter(data.from);
        return (
          <VStack
            w="full"
            key={i}
            px={2}
            alignItems="flex-start"
            justifyContent={"flex-start"}
            py={4}
            bg="green.100"
            rounded="lg"
            border="1px solid #ddd"
          >
            <HStack w="full" justifyContent={"space-between"}>
              <Box>
                <Text textTransform={"uppercase"} fontWeight={"bold"}>
                  {data.title}
                </Text>
              </Box>
              <HStack>
                <Button size="sm" onClick={()=>{updateEvent(data.id)}} bg="#fff">
                  modifier
                </Button>
                <Button size="sm" colorScheme={"orange"} onClick={()=>{deleteEvent(data.id)}} bg="red.600">
                  <TrashSimple size={18} color="#fff" />
                </Button>
              </HStack>
            </HStack>
            <VStack
              w="full"
              alignItems="flex-start"
              justifyContent={"flex-start"}
            >
              <Text>{data.description}</Text>
            </VStack>
            <HStack w="full">
              <Badge bg="green.400" color="#fff">
                du {dateFrom}
              </Badge>
              <Badge bg="red.400" color="#fff">
                au {dateTo}
              </Badge>
            </HStack>
          </VStack>
        );
      })}

<Modal isOpen={edit.isOpen} onClose={edit.onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={2}>
          <ModalHeader fontSize="sm">Modifier cet evenement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack w="full" spacing={4}>
              <FormControl>
                <FormLabel>Titre</FormLabel>
                <Input type="text" onChange={(e:any)=>{setEvent({...event,title:e.target.value})}} defaultValue={editData?.title} />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea defaultValue={editData?.description} onChange={(e:any)=>{setEvent({...event,description:e.target.value})}} />
              </FormControl>
              <FormControl>
                <FormLabel>Date de debut</FormLabel>
                <Input type="date" defaultValue={editData?.from} onChange={(e:any)=>{setEvent({...event,from:e.target.value})}} />
              </FormControl>
              <FormControl>
                <FormLabel>Date de fin</FormLabel>
                <Input type="date" defaultValue={editData?.to} onChange={(e:any)=>{setEvent({...event,to:e.target.value})}} />
              </FormControl>
              
             
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" colorScheme="blue" mr={3} onClick={edit.onClose}>
              annuler
            </Button>
            <Button
              size="sm" 
              onClick={editConfirmEvent}
              variant="ghost"
            >
              modifer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

<Modal isOpen={deletor.isOpen} onClose={deletor.onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="primary.50" m={2}>
          <ModalHeader fontSize="sm">Supprimer l'évenement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Voulez-vous vraiment retirer cet évenement ?</Text>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr={3} onClick={deletor.onClose}>
              annuler
            </Button>
            <Button size="sm" onClick={deleteEventConfirm} colorScheme={"red"} bg="red.600" color="#fff">
              supprimer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
