import React, { useCallback, useState } from "react";
import {
  Grid,
  GridItem,
  HStack,
  VStack,
  Button,
  Box,
  Input,
  Heading,
  Text,
  useBreakpointValue,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { SpeakerHigh } from "phosphor-react";
import { Editor } from "@tinymce/tinymce-react";
import { CREATE_FEED } from "../core/req";

export default function AddFeed() {
  const toast = useToast();
  const [AddFeeds, { loading }] = useMutation(CREATE_FEED);
  const width = useBreakpointValue({ base: "90%", lg: "50%" });
  const wgraph = useBreakpointValue({ base: "100vw", lg: "100%" });
  const editorRef = React.useRef<any>();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const publisher = useCallback(async () => {
    console.log(message.length);
    const feedsObservation = await AddFeeds({
      variables: {
        data: {
          title: title,
          message: message,
        },
      },
    });
    if (feedsObservation.data.createFeed) {
      toast({
        title: "Annonce faite avec succès",
        description: "Vous venez d'ajouter une annonce",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    //  window.location.reload();
    } else {
      toast({
        title: "Echec de publication",
        description: "Une erreur s'est produite !",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [message, AddFeeds, title, toast]);
  return (
    <VStack w="full" h="full" py={4} bg="#ddd">
      <VStack w={width} h="full" spacing={8}>
        <FormControl>
          <FormLabel>Titre de l'annonce</FormLabel>
          <Input
            bg="#fff"
            value={title}
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
          />
        </FormControl>
        <HStack
          w="full"
          spacing={0}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Editor
            apiKey="your-api-key"
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={message}
            onEditorChange={(value) => {
              setMessage(value);
            }}
            init={{
              width: `${wgraph}`,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </HStack>
        <FormControl>
          <Button
            onClick={publisher}
            color="primary.50"
            _hover={{ bg: "orangered" }}
            bg="primary.800"
          >
            PUBLIER
          </Button>
        </FormControl>
      </VStack>
    </VStack>
  );
}
