/* eslint-disable no-template-curly-in-string */
import {
  HStack,
  useBreakpointValue,
  Text,
  VStack,
  Button,
  useDisclosure,
  FormControl,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styles from "../styles/layout.module.css";
import React, { useCallback, useState } from "react";
import { globalStore } from "../store/store";
import { DELETE_REWARD, EDIT_REWARD } from "../core/req";
import { useMutation } from "@apollo/client";

export default function Rewards() {
  const position = useBreakpointValue({ base: "column", lg: "row" });
  const toast = useToast();
  const [rewardId, setRewardId] = useState("");
  const [EditRewards, { loading }] = useMutation(EDIT_REWARD);
  const [DeleteRewards] = useMutation(DELETE_REWARD);
  const { rewards, setRewards } = globalStore();
  const [editData, setEditData] = useState<any>();
  const edit = useDisclosure();
  const deletor = useDisclosure();
  const [reward, setReward] = useState({
    label: "",
    cash: "",
    gift: "",
    giftLabel: "",
    currency: "XOF",
  });
  const checkEditById = useCallback(
    (id: any) => {
      setRewardId(id);
      edit.onOpen();
      const data = rewards.find((e: any) => e.id === id);
      setEditData(data);
    },
    [edit, rewards]
  );

  const updateData = useCallback(async () => {
    const rewardData = await EditRewards({
      variables: {
        updateRewardId: rewardId,
        data: {
          label: reward.label || editData.label,
          cash: parseInt(reward.cash) || parseInt(editData?.cash),
          gift: parseInt(reward.gift) || parseInt(editData?.gift),
          giftLabel: reward.giftLabel || editData?.giftLabel,
          currency: "XOF",
        },
      },
    });
    if (rewardData.data.createReward.message) {
      toast({
        title: "La modification a échoué",
        description: `${rewardData.data.createReward.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Modifier avec succès",
        description: "Vous venez de modifier cette récompense",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [EditRewards, rewardId, reward.label, reward.cash, reward.gift, reward.giftLabel, editData, toast]);
const openDeleter = useCallback(
  (id:any) => {
    deletor.onOpen()
    setRewardId(id)
  },
  [deletor],
);
  const deleteReward = useCallback(async (id:any) => {
    
    const rewardData = await DeleteRewards({
      variables: {
        deleteRewardId: rewardId,
      },
    });
    deletor.onClose()
    if (rewardData.data.deleteReward.done) {
        toast({
          title: "Suppression avec succès",
          description: "Vous venez de supprimer cette récompense",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        window.location.reload()
      } else {
        toast({
          title: "Echec de la supression",
          description: "Une erreur s'est produite !",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
  }, [DeleteRewards, deletor, rewardId, toast]);
  return (
    <VStack w="full" px={8}>
      <Grid
        w="full"
        display="flex"
        py={8}
        flexDirection={position as any}
        templateColumns="repeat(`${rewards.length}`, 1fr)"
        gap={6}
      >
        {rewards?.map((data: any, i: any) => {
          return (
            <GridItem key={i} w="100%">
              <VStack
                w="full"
                boxShadow={"rgba(0, 0, 0, 0.5) 0px 25px 50px 0px"}
                bg="#fff"
                rounded="lg"
              >
                <HStack
                  w="full"
                  p={2}
                  roundedTop="lg"
                  justifyContent={"space-between"}
                  color="primary.50"
                  bg="green.400"
                >
                  <Text>{data?.label}</Text>
                  <Text p={2} bg="#fff" color="primary.900">
                    Niveau {data?.level}
                  </Text>
                </HStack>
                <HStack
                  w="full"
                  fontSize={"30px"}
                  fontWeight="bold"
                  px={4}
                  justifyContent={"flex-end"}
                >
                  <Text className={styles.gold}>
                    {data?.totalValue} {data?.currency}
                  </Text>
                </HStack>
                <HStack
                  w="full"
                  fontSize={"20px"}
                  fontWeight="bold"
                  px={4}
                  justifyContent={"flex-start"}
                >
                  <Text p={1} border="1px solid #ddd" rounded="lg">
                    {data?.giftLabel}
                  </Text>
                </HStack>
                <HStack
                  w="full"
                  py={4}
                  fontSize={"30px"}
                  fontWeight="bold"
                  px={4}
                  justifyContent={"flex-end"}
                >
                  <Button
                    size="sm"
                    onClick={() => checkEditById(data.id)}
                    colorScheme={"transparent"}
                    bg="orange.400"
                  >
                    modifier
                  </Button>
                  <Button
                    size="sm"
                    onClick={()=>openDeleter(data.id)}
                    colorScheme={"transparent"}
                    bg="red.400"
                  >
                    supprimer
                  </Button>
                </HStack>
              </VStack>
            </GridItem>
          );
        })}
      </Grid>

      <Modal isOpen={edit.isOpen} onClose={edit.onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={2}>
          <ModalHeader fontSize="sm">Modifier cette récompense</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack w="full" spacing={4}>
              <Input
                defaultValue={editData?.label}
                onChange={(e: any) => {
                  setReward({ ...reward, label: e.target.value });
                }}
                placeholder="Nom du label"
              />
              <Input
                defaultValue={editData?.cash}
                onChange={(e: any) => {
                  setReward({ ...reward, cash: e.target.value });
                }}
                placeholder="Valeur en cash"
              />
              <Input
                defaultValue={editData?.gift}
                onChange={(e: any) => {
                  setReward({ ...reward, gift: e.target.value });
                }}
                placeholder="Valeur en cadeau"
              />
              <Input
                defaultValue={editData?.giftLabel}
                onChange={(e: any) => {
                  setReward({ ...reward, giftLabel: e.target.value });
                }}
                placeholder="Nom du cadeau"
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" colorScheme="blue" mr={3} onClick={edit.onClose}>
              annuler
            </Button>
            <Button
              size="sm"
              onClick={updateData}
              isLoading={loading}
              variant="ghost"
            >
              modifer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={deletor.isOpen} onClose={deletor.onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="primary.50" m={2}>
          <ModalHeader fontSize="sm">Supprimer la récompense</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Voulez-vous vraiment retirer cette récompense ?</Text>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr={3} onClick={edit.onClose}>
              annuler
            </Button>
            <Button size="sm" onClick={deleteReward} colorScheme={"red"} bg="red.600" color="#fff">
              supprimer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
