import { gql } from "@apollo/client";

export const CREATE_USER = gql`

mutation Inscription($data: SignupInput) {
  adminSignup(data: $data) {
    ... on SignupSuccess {
      user {
        id
      }
    }
    ... on Error {
      message
      data
    }
  }
}
`;

export const FETCHALL_USER =  gql`
query Query {
  usersCount
}`

export const GET_USER_BYLEVEL = gql`
query Query($level: Int!) {
  usersCountByLevel(level: $level)
}
`

export const FIND_USER_BY_CODE = gql`
query Query($data: FindUserInput) {
  users(data: $data) {
    firstName
    email
    createdAt
    countryName
    referralCode
    country
    activated
    lastName
    level
    moovNumber
    mtnNumber
    id
    totalChildrenCount
    parent {
      firstName
      lastName
    }
    layer {
      size
      maxSize
    }
    rewards {
      id
      userId
      rewardId
      data
      for
      snapshot
      earnedAt
      cashPaidAt
      giftDeliveredAt
    }
  }
}`

export const CREATE_FEED = gql`
mutation Mutation($data: CreateFeedInput) {
  createFeed(data: $data) {
    ... on Error {
      errorCode
      message
      data
    }
    ... on Feed {
      id
      title
      message
      createdAt
      updatedAt
    }
  }
}`

export const GET_REWARDS = gql`
query Query {
  rewards {
    id
    level
    label
    cash
    gift
    totalValue
    giftLabel
    giftIsTicket
    currency
    createdAt
    updated
  }
}`

export const GET_LEVEL = gql`
query Layer($layerIndex: Int) {
  me {
    layer(index: $layerIndex) {
      size
      maxSize
      nodes {
        id
        firstName
        lastName
        level
      }
    }
  }
}
`;

export const EDIT_FEED = gql`
mutation Mutation($updateEventId: ID!) {
  updateEvent(id: $updateEventId) {
    ... on Event {
      id
      code
      title
      description
      from
      to
      extras
      createdAt
      updatedAt
    }
    ... on Error {
      errorCode
      message
      data
    }
  }
}`

export const GET_FEED = gql`
query Query {
  feeds {
    id
    title
    message
    createdAt
    updatedAt
  }
}`

export const DELETE_FEED = gql`
mutation Mutation($deleteFeedId: ID!) {
  deleteFeed(id: $deleteFeedId) {
    ... on BooleanResult {
      done
    }
    ... on Error {
      errorCode
      message
      data
    }
  }
}`

export const ADD_EVENT = gql`
mutation Mutation($data: CreateEventInput) {
  createEvent(data: $data) {
    ... on Event {
      title
      description
      from
      to
      code
    }
    ... on Error {
      errorCode
      message
      data
    }
  }
}`

export const GET_EVENT = gql`
query Query {
  events {
    id
    code
    title
    description
    from
    to
    extras
    createdAt
    updatedAt
  }
}`

export const EDIT_EVENT = gql`
mutation Mutation($updateEventId: ID!, $data: UpdateEventInput) {
  updateEvent(id: $updateEventId, data: $data) {
    ... on Event {
      id
      code
      title
      description
      from
      to
      extras
      createdAt
      updatedAt
    }
    ... on Error {
      errorCode
      message
      data
    }
  }
}`


export const DELETE_EVENT = gql`
mutation Mutation($deleteEventId: ID!) {
  deleteEvent(id: $deleteEventId) {
    ... on BooleanResult {
      done
    }
    ... on Error {
      errorCode
    }
  }
}`

export const CONNECT_USER = gql`

mutation Connexion($data: SigninInput) {
  signin(data: $data) {
    ... on SigninSuccess {
      user {
        id
        firstName
        lastName
        level
        referralCode
        activated
        verified
      }
      token
    }
    ... on Error {
      message
      data
    }
  }
}

`;

export const GET_USER = gql`
query Query {
  me {
    id
    firstName
    lastName
    email
    level
    mtnNumber
    parent{
      id
      firstName
      lastName
    }
    moovNumber
    createdAt
    layer(index:0){
      size
      maxSize
      nodes {
          id
          firstName
          lastName
          level
      }
    }
    referralCode
    networkSize
    totalChildrenCount
    activated
  }
}

`;

export const ADD_REWARD = gql`
mutation CreateReward($data: CreateRewardInput) {
  createReward(data: $data) {
    ... on Reward {
      id
      level
      label
      cash
      gift
      createdAt
    }
    ... on Error {
      errorCode
      message
      data
    }
  }
}`
 
export const EDIT_REWARD = gql`
mutation Mutation($updateRewardId: ID!, $data: UpdateRewardInput) {
  updateReward(id: $updateRewardId, data: $data) {
    ... on Error {
      errorCode
      message
      data
    }
    ... on Reward {
      id
      level
      label
      cash
      gift
      totalValue
      giftLabel
      currency
   
    }
  }
}`


export const DELETE_REWARD = gql`
mutation DeleteReward($deleteRewardId: ID!) {
  deleteReward(id: $deleteRewardId) {
    ... on Error {
      errorCode
      message
      data
    }
    ... on BooleanResult {
      done
    }
  }
}`

export const GET_REWARD = gql`
query Query {
  rewards {
    id
    level
    label
    cash
    gift
    totalValue
    giftLabel
    currency
  }
}`

export function getUser() {
  const token = localStorage.removeItem("w-token")
  return fetch("https://winners.softwaiz.co/api/me",
    {
      method:"POST",
      headers: { 'Content-Type': 'application/json;charset=utf-8', Authorization: "bearer " + token },
      body: JSON.stringify(GET_USER)
    })
    .then((res) => res.json())
    .then((res => {
      console.log(res);
    }))

}

