/* eslint-disable jsx-a11y/alt-text */
import { HStack, Button, VStack, Text, Box, Avatar, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, useDisclosure, ModalBody, ModalFooter, useBreakpointValue, DrawerFooter } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import React, { useCallback } from 'react';
import { User, SpeakerHigh, Money, Table, Trophy, Alarm, Wrench, MagnifyingGlass, UserPlus } from 'phosphor-react';
import { Link, NavLink } from 'react-router-dom';
import styles from "../styles/layout.module.css"
import { IconButton } from '@chakra-ui/react'
import logo from "../img/logo.png"
import { Bell, List } from 'phosphor-react';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from '@chakra-ui/react';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'

export default function NavBar() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const observe = useBreakpointValue({ base: false, lg: true })
    const pad = useBreakpointValue({ base: "8", lg: "0" })
    const border = useBreakpointValue({ base: "none", lg: "1px solid grey" })
    const drawer = useDisclosure()
    const mob = useDisclosure()
    const btnRef = React.useRef()
    const navigate = useNavigate()
    const logout = useCallback(() => {
        localStorage.removeItem("w-token");
        navigate("/");
    }, [navigate]);
    return (
        <Stack w='100%'>
            <HStack h='50px' bg="green.400" w='full' px={4} py={pad} justifyContent="space-between">
                {!observe && <IconButton variant={"outline"} onClick={mob.onOpen} color="#ddd" colorScheme={"transparent"} aria-label='Search database' icon={<List size={18} />} />}
                <Box py={8}><Text color="primary.50">WINNERS CLUB ADMIN</Text></Box>
                <HStack>
                    {observe && <IconButton marginRight={8} color="#fff" ref={btnRef} onClick={drawer.onOpen} _hover={{ bg: 'transparent' }} colorScheme={"teal"} variant='ghost' aria-label='Search database' icon={<Bell size={18} />} />}
                    <Menu isLazy>
                        <MenuButton>
                            <Avatar src="/dev.png" size='sm' />
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={onOpen}>Déconnexion</MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />

                <ModalContent m={1} bg="green.800" color="primary.50">
                    <ModalHeader>Déconnexion de l'admin</ModalHeader>
                    <ModalBody>
                        <Text>Voulez-vous vraiment partir ?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' size={"sm"} mr={3} onClick={onClose}>
                            Annuler
                        </Button>
                        <Button colorScheme='blue' size={"sm"} onClick={logout}>Oui</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Drawer
                isOpen={drawer.isOpen}
                placement='right'
                onClose={drawer.onClose}
                finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent bg={"green.900"} color="#fff">
                    <DrawerCloseButton />
                    <DrawerHeader>Notifications</DrawerHeader>
                    <DrawerBody>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
            <Drawer
                isOpen={mob.isOpen}
                placement='left'
                onClose={mob.onClose}
                finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent bg={"green.900"} color="#fff">
                    <DrawerCloseButton />
                    <DrawerHeader><Link to="/home" onClick={mob.onClose}><Box w='50px' p={2} rounded="full" bg="#fff"><img src={logo} height={"50px"} width="50px" /></Box></Link></DrawerHeader>
                    <DrawerBody>
                        <VStack w="full" alignItems="flex-start" spacing={0}>
                            <NavLink to="/home" onClick={mob.onClose} className={({ isActive }) => isActive ? styles['homeLink'] : styles['hoverLink']}>
                                <Table size={24} />
                                <Text ml={4}>TABLEAU DE BORD</Text>
                            </NavLink>

                            <NavLink to="/home/users" onClick={mob.onClose} className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                                <MagnifyingGlass size={24} />
                                <Text ml={4}>RECHERCHER</Text>
                            </NavLink>
                            <NavLink to="/home/signup" onClick={mob.onClose} className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                                <UserPlus size={24} />
                                <Text ml={4}>INSCRIPTION</Text>
                            </NavLink>
                            <NavLink to="/home/packs" onClick={mob.onClose} className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                                <Trophy size={24} />
                                <Text ml={4}>RECOMPENSES</Text>
                            </NavLink>

                            <NavLink to="/home/winners" onClick={mob.onClose} className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                                <User size={24} />
                                <Text ml={4}>GAGNANTS</Text>
                            </NavLink>

                            <NavLink to="/home/payment" onClick={mob.onClose} className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                                <Money size={24} />
                                <Text ml={4}>PAIEMENTS</Text>
                            </NavLink>

                            <NavLink to="/home/events" onClick={mob.onClose} className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']}>
                                <Alarm size={24} />
                                <Text ml={4}>EVENEMENTS</Text>
                            </NavLink>

                            <NavLink to="/home/feeds" onClick={mob.onClose} className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']} >
                                <SpeakerHigh size={24} />
                                <Text ml={4}>ANNONCES</Text>
                            </NavLink>

                            <NavLink to="/home/settings" onClick={mob.onClose} className={({ isActive }) => isActive ? styles['activeLink'] : styles['hoverLink']} >
                                <Wrench size={24} />
                                <Text ml={4}>REGLAGES</Text>
                            </NavLink>

                        </VStack>
                    </DrawerBody>
                    <DrawerFooter>
                        <HStack><Text fontSize="10px">by SOFTWAIZ | FKC</Text></HStack>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Stack>
    );
}
