import {
  Grid,
  GridItem,
  HStack,
  VStack,
  Box,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { User } from "phosphor-react";
import React from "react";
import { useStatistics } from "../store/store";


export default function LevelStat() {
    const position = useBreakpointValue({ base: "column", lg: "row" });
    const { levels } = useStatistics()
  return <VStack w="full" py={8}>
 <Grid
        w="full"
        display="flex"
        flexDirection={position as any}
        templateColumns="repeat(4, 1fr)"
        gap={6}
      >
        <GridItem w="100%" color="#000" p={4} rounded="lg" bg="#fff">
          <HStack w='full' justifyContent={"flex-start"}>
            <Text fontSize={"sm"} fontWeight="bold">UTILISATEURS AU NIVEAU 0</Text>
          </HStack>
          <HStack w='full' justifyContent={"space-between"}>
            <Text fontSize={"6xl"}>{levels["0"] as any}</Text>
            <User size={24} />
          </HStack>
        </GridItem>
        <GridItem w="100%" color="#fff" p={4} rounded="lg" bg="grey">
          <HStack w='full' justifyContent={"flex-start"}>
            <Text fontSize={"sm"} fontWeight="bold">UTILISATEURS AU NIVEAU 1</Text>
          </HStack>
          <HStack w='full' justifyContent={"space-between"}>
            <Text fontSize={"6xl"}>{levels["1"] as any}</Text>
            <User size={24} />
          </HStack>
        </GridItem>
        <GridItem w="100%" color="#fff" p={4} rounded="lg" bg="yellow.500">
          <HStack w='full' justifyContent={"flex-start"}>
            <Text fontSize={"sm"} fontWeight="bold">UTILISATEURS AU NIVEAU 2</Text>
          </HStack>
          <HStack w='full' justifyContent={"space-between"}>
            <Text fontSize={"6xl"}>{levels["2"] as any}</Text>
            <User size={24} />
          </HStack>
        </GridItem>
        <GridItem w="100%" color="#fff" p={4} rounded="lg" bg="#292929">
          <HStack w='full' justifyContent={"flex-start"}>
            <Text fontSize={"sm"} fontWeight="bold">UTILISATEURS AU NIVEAU 3</Text>
          </HStack>
          <HStack w='full' color="#fff" justifyContent={"space-between"}>
            <Text fontSize={"6xl"}>{levels["3"] as any}</Text>
            <User size={24} />
          </HStack>
        </GridItem>
      </Grid>
  </VStack>;
}
