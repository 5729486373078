import { HStack } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router';

export default function Content() {
  return (
    <HStack bg="#fff"  color="primary.900" w='full' height={"auto"}>
      <Outlet />
    </HStack>
  );
}
