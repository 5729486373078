import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { baseTheme } from "./theming";
import "./App.css";
import Login from "./components/login";
import Layout from "./components/Layout";
import Statistics from "./views/Statistics";
import Users from "./views/Users";
import Packs from "./views/Packs";
import AddPacks from "./views/AddPacks";
import PaidEnter from "./views/PaidEnter";
import PaidOut from "./views/PaidOut";
import Protector from "./components/Protector";
import { ApolloProvider } from "@apollo/client";
import client from "../src/lib/apollo-client";
import Initializer from "./components/Initializer";
import Events from "./views/Events";
import Settings from "./views/Settings";
import User from "./views/User";
import AddEvent from "./views/AddEvent";
import Feeds from "./views/Feeds";
import AddFeed from "./views/AddFeed";
import Winners from "./views/Winners";
import SignUp from "./views/signup";

function App() {
  return (
    <ApolloProvider client={client}>
      <Initializer>
        <ChakraProvider theme={baseTheme}>
          <Router>
            <Routes>
              <Route element={<Protector />}>
                <Route path="/home" element={<Layout />}>
                  <Route index element={<Statistics />}  />
                  <Route path="users" element={<Users />}  />
                  <Route path="signup" element={<SignUp />}  />
                  <Route path="users/:id" element={<User />}  />
                  <Route path="events" element={<Events />} />
                  <Route path="packs" element={<Packs />}  />
                  <Route path="addpack" element={<AddPacks />} />
                  <Route path="addevent" element={<AddEvent />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="payment" element={<PaidEnter />} />
                  <Route path="paidout" element={<PaidOut />} />
                  <Route path="feeds" element={<Feeds />} />
                  <Route path="winners" element={<Winners />} />
                  <Route path="addfeed" element={<AddFeed />} />
                </Route>
              </Route>
              <Route path="/" element={<Login />} />
            </Routes>
          </Router>
        </ChakraProvider>
      </Initializer>
    </ApolloProvider>
  );
}

export default App;
